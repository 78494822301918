<template>
  <CContainer class="min-vh-100 d-flex align-items-center">
    <CRow class="w-100 justify-content-center">
      <CCol col="12" sm="12" md="9" lg="7" xl="6" >
        <CCard class="mb-0 mx-0">
          <CCardBody class="p-4">
            <CForm
                    :was-validated="wasValidated"
                    @submit.prevent="create"
                    novalidate
            >
              <h1 v-if="label">Реєстрація юридичної особи</h1>
              <h1 v-else>Реєстрація ФОП</h1>
              <CAlert v-if="success" show color="success">Деталі відправленно вам на пошту</CAlert>
              <CAlert v-if="failed" show color="danger">{{failed_message}}. <CLink target="_blank" to="/faq">Не виходить зареєструватися?</CLink></CAlert>
              <CAlert v-if="error_password" show color="danger">Пароль введено не правильно. <CLink target="_blank" to="/faq">Не виходить зареєструватися?</CLink></CAlert>
              <CRow>
                <CCol col="6">
                  <p class="text-muted">Дані Учасника</p>
                </CCol>
                <CCol col="6" class="text-right">
                  <CButtonGroup>
                    <CButton

                            class="p-1 px-3"
                            @click="checkLableForUsersStatusLegal"
                            :color="whatPersone(label)"
                            title="Юридична особа"
                    >ЮР</CButton>
                    <CButton

                            class="p-1"
                            @click="checkLableForUsersStatusFOP"
                            :color="whatFOP(label)"
                            title="Фізична особа-підприємець"
                    >ФОП</CButton>
                  </CButtonGroup>
                </CCol>
              </CRow>
              <template v-if="label">
                  <CInput
                        placeholder="ЄДРПОУ"
                        autocomplete="ЄДРПОУ"
                        aria-valuemax="8"
                        required
                        invalid-feedback="Введіть коректно дані (8 цифр)"
                        :is-valid="isNumberEDR"
                        v-model.trim="provider.edr"
                >
                  <template #prepend-content><CIcon name="cil-pencil"/></template>
                </CInput>
                <CInput
                        placeholder="Юридична особа"
                        autocomplete="юридична особа"
                        required
                        invalid-feedback="Введіть ім'я організації"
                        :disabled='isDisabledInputLegalPerson'
                        :is-valid="isLegalPerson"
                        v-model.trim="provider.name"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                  {{provider.name}}
                </CInput>
              </template>
              <template v-if="!label">
                <CInput
                        placeholder="ІПН"
                        autocomplete="ІПН"
                        required
                        invalid-feedback="Введіть ІПН (10 цифр)"
                        :is-valid="isNumberIPN"
                        v-model="provider.ipn"
                >
                  <template #prepend-content><CIcon name="cil-pencil"/></template>
                </CInput>
                <CInput
                        placeholder="ПІБ фізичної особи-підприємця"
                        autocomplete="Назва організації"
                        required
                        invalid-feedback="Введіть ПІБ фізичної особи-підприємця"
                        :is-valid="isLegalPerson"
                        v-model.trim="provider.name"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                  {{provider.name}}
                </CInput>
              </template>
                <CInput
                        label="Дані Користувача"
                        placeholder="Ім'я"
                        autocomplete="first_name"
                        required
                        invalid-feedback="Введіть ім'я"
                        :is-valid="isFirstNamePerson"
                        v-model="provider.first_name"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                        placeholder="Прізвище"
                        autocomplete="last_name"
                        required
                        invalid-feedback="Введіть прізвище"
                        :is-valid="isLastNamePerson"
                        v-model="provider.last_name"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
              <CInput
                      placeholder="Email"
                      autocomplete="email"
                      prepend="@"
                      required
                      invalid-feedback="Введіть коректно email"
                      :is-valid="validateEmail"
                      v-model="provider.email"
              />
              <CInput
                      placeholder="Пароль"
                      :type="passwordFieldIdType"
                      required
                      autocomplete="new-password"
                      invalid-feedback="Довжина пароля повина бути більше 6 символів"
                      :is-valid="confirmPassword"
                      v-model="provider.password"
              >
                <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                <template #append-content>
                  <a @click="showPass" v-c-tooltip="{
                                    content: 'Показати/сховати пароль',
                                    placement: 'top'
                                }"><CIcon name="cil-low-vision"/></a>
                </template>
              </CInput>
              <CInput
                      placeholder="Повторити пароль"
                      :type="passwordFieldIdType"
                      autocomplete="new-password"
                      class="mb-4"
                      required
                      invalid-feedback="Підтвердіть пароль"
                      :is-valid="confirmPasswordTwo"
                      v-model="provider.confirm_password"
              >
                <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                <template #append-content>
                  <a @click="showPass" v-c-tooltip="{
                                    content: 'Показати/сховати пароль',
                                    placement: 'top'
                                }"><CIcon name="cil-low-vision"/></a>
                </template>
              </CInput>
              <CInputCheckbox
                        class="pb-1"
                        name="name"
                        required
                        aria-required="false"
                        invalid-feedback="Підтвердіть умови тендеру"
                        :is-valid="confirmRules"
                        v-bind:checked.sync="acceptRules"
              />
              <span class="pl-4 pt-2"><span @click="acceptRules=true">Я ознайомлений та згоден з </span><a class="conditions-tender" @click="goToFAQ">Умовами та правилами участі</a> в міні-тендері</span>

            </CForm>
          </CCardBody>
          <CCardFooter>
            <CRow>
              <CCol>
                <vue-recaptcha ref="recaptcha"
                               @verify="onVerify"
                               sitekey="6LdbCKgZAAAAAAogKDYhUo5ksCGk_uSDHge2OeML"
                               :loadRecaptchaScript="true"
                >
                </vue-recaptcha>
              </CCol>
            </CRow>
            <CRow class="mt-1">
              <CCol col="12" sm="4" class="mr-auto mt-1 text-left">
                <CButton
                    @click="goBack"
                    color = "secondary"
                    type="button"
                    block >Назад</CButton>
              </CCol>
              <CCol col="12" sm="5" class="mt-1 text-right">
                <CButton
                    v-if="loading"
                    color="primary"
                    class="btn-block "
                    disabled
                >
                  <div class="  align-items-center">
                    <CSpinner color="default" size="sm"/>
                  </div>
                </CButton>
                <CButton
                    v-else
                    @click="create"
                    class = "btn-block"
                    color = "primary"
                    type="button"
                    :disabled='isDisabledCreateAccountBtn'
                    block >Створити</CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
        <CRow class="justify-content-center mb-0">
          <CLink target="_blank" to="/faq">Інформація про сервіс</CLink>
        </CRow>
      </CCol>

    </CRow>

  </CContainer>

</template>

<script>
import axios from 'axios'
// import conditional from './TenderConditionals'
import { email, required, minLength } from 'vuelidate/lib/validators'
import VueRecaptcha from 'vue-recaptcha';
import {routerGoToName} from "@/utils/routerUtils";

export default {
  name: 'Register',
  data() {
    return {
      passwordFieldIdType:'password',
      robot:false,
      failed: false,
      failed_message:"Щось пішло не так... Спробуйте пізніше ще раз",
      success: false,
      loading: false,
      wasValidated:false,
      error_password:false,
      isDisabledButtonLegalPerson:true,
      isDisabledButtonFOP:false,
      isDisabledInputLegalPerson:true,
      isDisabledCreateAccountBtn:true,
      confirmModal: false,
      provider:{
        name: "",
        edr: "",
        ipn: "",
        email: "",
        password: "",
        confirm_password: "",
        first_name: "",
        last_name: "",
      },
      label: true,
      acceptRules: false,
    }
  },
  components:{
    // conditional: conditional,
    VueRecaptcha ,
  },
  validations:{
    provider:{
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      },
      confirm_password:{
        required,
        minLength: minLength(6)
      },
      first_name:{
        required
      },
      last_name: {
        required
      },
    },
  },
  methods: {
    goToFAQ(){
        let quar = encodeURIComponent('Умови та правила участі в міні-тендері');
        let url = `/faq?q=${quar}`
        window.open(url,'_blank')
        // this.$router.push({path: url})
    },
    whatPersone(is){
      return is ? 'primary' : 'secondary'
    },
    whatFOP(is){
      return is ? 'secondary' :'primary'
    },
    showPass(){
      this.passwordFieldIdType=this.passwordFieldIdType === 'password'?'text':'password';
    },
    goBack(){
      // this.$router.go(-1)
      routerGoToName('Login')
        // this.$router.push({path: `/login`});
    },
    onVerify(){
      this.robot=true;
    },
    checkLableForUsersStatusLegal(){
      this.label = true;
      this.provider.ipn = "";
    },
    checkLableForUsersStatusFOP(){
      this.label = false;
      this.provider.edr = "";
    },
    isNumberEDR(val) {
      if (val === '') {
        return
      }
      let num = Number(val);
      let len = num.toString().length;
      if (len !== 8) {
        this.isDisabledInputLegalPerson = true;
        return false
      }
      this.checkEDR(val);
      return Number.isInteger(num)
    },
    isLegalPerson(val){
      if (val === '') {
        return ;
      }
      let len = val.length;
      if (len >= 100) {
        return false
      }
      this.name = val;
      return true;
    },
    isNumberIPN(val) {
      if (val === '') {
        return
      }
      let num = Number(val);
      let len = num.toString().length;
      if (len !== 10) {
        return false
      }
      return Number.isInteger(num)
    },
    validateEmail(email) {
      if (email === '') {
        return
      }
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    confirmPassword(val) {
      if (val === '') {
        return
      }
      if (val.length < 6 ) {
        return false
      }
      if(val.length>=6){
          return true;
      }
      return false;
    },
      confirmPasswordTwo(val){
          if (val === '') {
              return
          }
          if (val.length < 6 ) {
              return false
          }
          if (this.provider.password === this.provider.confirm_password){
              return this.provider.password === val
          }
          return false
      },
    confirmRules(val) {
      if (val === false) {
        this.isDisabledCreateAccountBtn =true
        return
      }
      this.isDisabledCreateAccountBtn =false
      return this.acceptRules
    },
    isFirstNamePerson(val){
      if (val === '') {
        return
      }
      let len = val.length;
      if (len < 2 || len >= 15) {
        return false
      }
      return this.provider.first_name = val
    },
    isLastNamePerson(val){
      if (val === '') {
        return
      }
      let len = val.length;
      if (len < 2 || len >= 20) {
        return false
      }
      return this.provider.last_name = val
    },
    checkEDR(edr){
      let self = this;
      axios.get('https://edr.data-gov-ua.org/api/companies?edrpou='+ edr)
              .then(function(response){
                if (response.data.length === 0){
                  self.provider.name ="";
                  self.isDisabledInputLegalPerson = false;
                }
                response.data.forEach(function (v) {
                  if (v.status === "зареєстровано") {
                      if(v.name === ''){
                          self.provider.name = v.officialName;
                          self.isDisabledInputLegalPerson = true;
                          return ;
                      }
                    self.provider.name = v.name;
                    self.isDisabledInputLegalPerson = true;
                  }
                });
              })
              .catch(function(){
                self.provider.name ="";
                self.isDisabledInputLegalPerson = false;
              });
    },
    create() {
      if (!this.acceptRules) {
        return
      }
      if (this.$v.$invalid) {
         this.wasValidated = true;
         return;
       }
      if(this.provider.name === ''){
          this.wasValidated = true;
          return
      }
        if (this.provider.password !== this.provider.confirm_password){
            this.error_password=true;
            return
        }
        if (process.env.VUE_APP_CYPRESS_TT !== "test"){
          if (!this.robot){
            this.failed_message= "Підтвердіть, що ви не робот";
            this.failed = true;
            return;
          }
      }
      let self = this;
      this.loading = true;
      axios.post('/api/account/register', self.provider)
            .then(function (response) {
                self.error_password=false;
                self.loading = false;
                if (response.status === 201){
                    self.success= true;
                    setTimeout(function(){
                      self.$router.push({path: `/login`})
                    }, 3000);
                    return;
                }
                self.failed = true;
            })
            .catch(function(error) {
              self.loading = false;
                switch (error.response.data.message) {
                  case 'EmailAlreadyUsed':
                    self.failed_message="Цей Email вже використовується іншим користувачем";
                    break;
                  case 'IPNExist':
                    self.failed_message="Цей ІПН вже використовується іншим користувачем";
                    break;
                  case 'EDRExist':
                    self.failed_message="Цей ЄДРПОУ вже використовується іншим користувачем";
                    break;
                  case 'EmptyIPNorEDR':
                    self.failed_message="Відсутній ІПН чи ЄДРПОУ, заповніть їх";
                    break;
                  default:
                    self.failed_message="Щось пішло не так... Спробуйте пізніше ще раз";
                }
              self.failed = true;
            });
    }
    }
}
</script>
