import router from "@/router";

export function findPathByName(routes, name, parentPath = '') {
    let path;
    for (const route of routes) {
        if (route.name === name) {
            return route.path;
        }

        if (route.children && route.children.length) {
            const childrenRoute = findPathByName(route.children, name, route.path);

            if (childrenRoute) {
                console.log(childrenRoute)
                path = childrenRoute;
                return `${route.path}/${path}`
            }
        }
    }
    return path;
}

export function getPathByName(name) {
    const path = findPathByName(router.options.routes, name);
    console.log(path)
    return path;
}

export function routerGoToName(name, parameter, parameterData) {
    if (!parameter) return router.push({name: name});
    if (parameter === 'query' && parameterData) return router.push({
        name: name,
        query: parameterData
    });
    if (parameter === 'params') return router.push({
        name: name,
        params: parameterData
    });
    if (parameter === 'params-query') {
        let {params, query} = {...parameterData};
        return router.push({
            name: name,
            params: params,
            query: query
        });
    }
}